import React, { useState, useRef, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import _settings from '../../../style/config/_settings.scss';

export const Slider_1 = (props) => {

    const { imgs, onClickCb, fill } = props

    const swiper = useRef(null)

    const [ slidesPerView, setSlidesPerView ] = useState(( window.innerWidth <= 576 ) ? 1 : 3)
    const [ isBeginning, setIsBeginning ] = useState(true)
    const [ isEnd, setIsEnd ] = useState(false)

    useEffect(() => {

        window.addEventListener('resize', () => {
            if (window.innerWidth <= 992) {
                setSlidesPerView(1)
            }
    
            if(window.innerWidth > 992) {
                setSlidesPerView(3)
            }
        })

        return () => {
            window.removeEventListener('resize', () => {
                if (window.innerWidth <= 992) {
                    setSlidesPerView(1)
                }
        
                if(window.innerWidth > 992) {
                    setSlidesPerView(3)
                }
            })
        }

    }, [window.innerWidth])

    const handlePrevClick = () => {
        if (swiper) {
            swiper.current.slidePrev()
        }
    }

    const handleNextClick = () => {
        console.log("Next click")
        if (swiper) {
            swiper.current.slideNext()
        }
    }

    const handleSlideChange = (e) => {

        console.log("isBeginning", e.isBeginning)
        console.log("isEnd", e.isEnd)

        setIsBeginning(e.isBeginning)
        setIsEnd(e.isEnd)
    }

    return (
        <>
            <div className="row d-flex justify-content-center" style={{ overflow: "hidden" }}>
                <div className="slider1__swiper-container d-flex-justify-content-center align-items-center">
                    <div className="slider1__swiper-wrapper d-flex justify-content-center align-items-center">
                        <div className="slider1__swiper-pagination-prev" onClick={handlePrevClick}>
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512">
                                <path style={{
                                    fill: (isBeginning) ? _settings.themeMediumGray : fill
                                }} d="M447.1,256c0,17.7-13.4,32-31.1,32H109.3l105.4,105.4c12.5,12.5,12.5,32.8,0,45.3c-6.3,6.3-14.5,9.4-22.7,9.4
	                    s-16.4-3.1-22.6-9.4l-160-160c-12.5-12.5-12.5-32.8,0-45.3l160-160c12.5-12.5,32.8-12.5,45.3,0s12.5,32.8,0,45.3L109.3,224H416
	                    C433.7,224,447.1,238.3,447.1,256z"/>
                            </svg>
                        </div>
                        <Swiper
                            onAfterInit={(e) => swiper.current = e}
                            slidesPerView={slidesPerView}
                            spaceBetween={30}
                            onSlideChange={handleSlideChange}
                        >
                            {
                                imgs.map(img => (
                                    <SwiperSlide className="slider1__swiper-slide d-flex justify-content-center align-items-center">
                                        <img src={img} onClick={onClickCb} />
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                        <div className="slider1__swiper-pagination-prev" onClick={handleNextClick}>
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512">
                                <path style={{ 
                                    fill:  (isEnd) ? _settings.themeMediumGray : fill
                                }} d="M438.6,278.6l-160,160c-6.2,6.3-14.4,9.4-22.6,9.4s-16.4-3.1-22.6-9.4c-12.5-12.5-12.5-32.8,0-45.3L338.8,288H32
	                    c-17.7,0-32-14.3-32-32s14.3-32,32-32h306.8L233.4,118.6c-12.5-12.5-12.5-32.8,0-45.3s32.8-12.5,45.3,0l160,160
	                    C451.1,245.9,451.1,266.1,438.6,278.6z"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
