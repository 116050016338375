import React from 'react'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'

import { fetchNoToken } from '../../helpers/fetch'

export const ContactSection = () => {

  const ContactSchema = Yup.object().shape({
    name: Yup.string().trim()
      .min(1, "Este campo no debe de estar vacio")
      .max(255, "Este campo solo acepta 255 caracteres")
      .required('Campo obligatorio'),

    subject: Yup.string().trim()
      .min(1, "Este campo no debe de estar vacio")
      .max(255, "Este campo solo acepta 255 caracteres")
      .required('Campo obligatorio'),

    email: Yup.string().trim()
      .email("Email inválido")
      .required('Campo obligatorio'),

    message: Yup.string().trim()
      .min(1, "Este campo no debe de estar vacio")
      .max(500, "Este campo solo acepta 500 caracteres")
      .required('Campo obligatorio'),
  });

  const initialValues = {
    name: '',
    subject: '',
    email: '',
    message: ''
  }

  const handleFormSubmit = async ({ isValid, values }) => {

    const { name, subject, email, message } = values;

    console.log({
      isValid,
      values
    });

    if (isValid) {
      try {
        const res = await fetchNoToken('mailer.php', { name, subject, email, msg: message }, "POST");

        const body = await res.json();

        console.log(res.status);
        console.log(body);

        if (body.ok) {
          toast.success(body.msg, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(body.msg, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        console.log("Request body:", body);
      } catch (e) {
        console.log(e);
        toast.error("Error del servidor", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  return (
    <div className="contactSection__main-div">
      <div className="container">
        <div className="contactSection__form-wrapper d-flex flex-column align-items-center">
          <h2 className="base__fsc-hero-2 base__fs --poppins --color-white --w-bold text-center">Contactanos</h2>
          <Formik
            initialValues={initialValues}
            validationSchema={ContactSchema}
            onSubmit={handleFormSubmit}
            validate={false}
          >
            {
              ({ errors, isValid, values, setErrors }) => (
                <form className="contactSection__form mt-3 d-flex flex-column align-items-center">
                  <div className="form-group">
                    <Field className="form-control"
                      placeholder='Nombre'
                      type="text"
                      id="name"
                      name='name'
                    />
                    <div className="contactSection__error-text form-text color-white">{errors.name}</div>
                  </div>
                  <div className="form-group">
                    <Field className="form-control"
                      placeholder='Asunto'
                      type="text"
                      id="subject"
                      name='subject'
                    />
                    <div className="contactSection__error-text form-text color-white">{errors.subject}</div>
                  </div>
                  <div className="form-group">
                    <Field className="form-control"
                      placeholder='Email'
                      type="email"
                      id="email"
                      name="email"
                    />
                    <div className="contactSection__error-text form-text color-white">{errors.email}</div>
                  </div>
                  <div className="form-group">
                    <Field className="form-control"
                      as="textarea"
                      type="text"
                      id="message"
                      name="message"
                      placeholder="Mensaje"
                    />
                    <div className="contactSection__error-text form-text color-white">{errors.message}</div>
                  </div>
                  <button type='button' className="base__btn white mt-4" onClick={() => handleFormSubmit({ values, isValid })}><h4>Enviar</h4></button>
                </form>
              )
            }
          </Formik>
        </div>
      </div>
    </div>
  )
}
