import React, { useEffect, useRef } from 'react'

export const ClientsSection = () => {

  const hexagonsContainer = useRef(null)

  useEffect(() => {

    if (hexagonsContainer.current) {

      setHexagons();

    }

  }, [hexagonsContainer])

  useEffect(() => {
    window.addEventListener('resize', () => setHexagons())

    return () => {
      window.removeEventListener('resize', () => setHexagons())
    }
  }, [window.offsetWidth])

  const setHexagons = () => {
    if (hexagonsContainer.current) {
      const hexagons = hexagonsContainer.current.querySelectorAll(".clientsSection__hexagon");
      const rows = hexagonsContainer.current.querySelectorAll('.row')

      hexagons.forEach(hexagon => hexagon.style.height = hexagon.offsetWidth + "px")
      rows.forEach((row, index) => {

        row.style.marginTop = -(hexagons[0].offsetWidth / 6) + "px"

      })
    }
  }

  return (
    <div className="clientsSection__main"
      style={{
        backgroundImage: "url(/assets/img/illustration/IMG_6472JPG.jpg)"
      }}
    >
      <div className="clientsSection__wrapper">
        <h1 className="text-center base__fsc-hero base__fs --w-bold --poppins --color-white">Nuestros Clientes</h1>
        <h4 className="text-center base__fsc-8 base__fs --w-bold --raleway --color-white ps-3 pe-3 mt-4">Contamos con la confianza de más de 100 clientes</h4>
        <div className="container clientsSection__hexagon-container" ref={hexagonsContainer}>

          <div className="row">
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img27.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img29.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img31.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img33.jpg' />
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img35.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img36.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img37.jpg' />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img39.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img41.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img42.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon" style={{
                backgroundColor: "#00cc99"
              }}>
                <img src='/assets/img/logos/clientes/img43.png' />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img45.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img47.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img49.jpg' />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img50.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img51.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img52.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img53.jpg' />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img55.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img57.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img58.jpg' />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img59.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img60.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon">
                <img src='/assets/img/logos/clientes/img63.jpg' />
              </div>
            </div>
            <div className="col-3">
              <div className="clientsSection__hexagon --fill">

              </div>
            </div>
          </div>
        </div>
        <svg className="clientsSection__decorator-bottom" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 85">
          <path d="M1440,21v64H0V21l80-5.3C160,10,320,0,480,10.3C640,21,800,53,960,58.3c160,5.7,320-16.3,400-26.6L1440,21z" />
        </svg>
      </div>
    </div>
  )
}
