import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

export const Navbar = () => {

    const navigate = useNavigate()

    const linksContainer = useRef(null)

    const [showMenu, setShowMenu] = useState(false);

    const handleLinkClick = (path, state = {}) => {

        navigate(path, { ...state });

    }

    const handleDropdownClick = (e) => {
        e.stopPropagation();

        const { target } = e;

        console.log("Target:", target)

        const dropdown = target.parentNode.querySelector('.navbar__dropdown')

        dropdown.style.display = "block"

    }

    useEffect(() => {

        window.addEventListener('click', () => {
            if (linksContainer.current) {
                const dropdowns = linksContainer.current.querySelectorAll('.navbar__dropdown')

                dropdowns.forEach(element => {
                    element.style.display = "none"
                });
            }
        })

        return () => {
            window.removeEventListener('click', () => {
                if (linksContainer.current) {
                    const dropdowns = linksContainer.current.querySelectorAll('.navbar__dropdown')
    
                    dropdowns.forEach(element => {
                        element.style.display = "none"
                    });
                }
            })
        }
    }, [])

    useEffect(() => {

        window.addEventListener('resize', () => {
            console.log("event");
            (window.innerWidth > 768) ? setShowMenu(false) : setShowMenu(true)
        })

        return () => {
            window.removeEventListener('resize', () => {
                console.log("event");
                (window.innerWidth > 768) ? setShowMenu(false) : setShowMenu(true)
            })
        }
    })

    return (
        <>
            <div className="navbar__spacer"></div>
            <div className="navbar__main">
                <div className={`navbar__wrapper d-flex ${(showMenu) ? "--active" : ""}`}>
                    <div className="navbar__logo-wrapper">
                        <img className="navbar__logo" src="/assets/img/logos/publicidad_md_logo.png"></img>
                    </div>
                    <ul ref={linksContainer} className={`navbar__links-container d-flex align-items-center ${(showMenu) ? "--active" : ""}`}>
                        <li onClick={() => handleLinkClick("/index", { state: 'sec-presentation' })}><h6>Inicio</h6></li>
                        <li className="navbar__dropdown-menu">
                            <h6 onClick={handleDropdownClick}>Servicios</h6>
                            <div className="navbar__dropdown">
                                <li onClick={() => handleLinkClick("/index", { state: 'sec-events' })}><h6>Eventos y convenciones</h6></li>
                                <li onClick={() => handleLinkClick("/index", { state: 'sec-stands' })}><h6>Stands</h6></li>
                            </div>
                        </li>
                        <li onClick={() => handleLinkClick("/index", { state: 'sec-clients' })}><h6>Nuestros clientes</h6></li>
                        <li onClick={() => handleLinkClick("/index", { state: 'sec-values' })}><h6>Acerca de nosotros</h6></li>
                        <li onClick={() => handleLinkClick("/index", { state: 'sec-contact' })}><h6>Contacto</h6></li>
                    </ul>
                    <div className="navbar__button-container">
                        <button className="navbar__button-container" onClick={() => setShowMenu(!showMenu)}>
                            <i className="fa-solid fa-bars"></i>
                        </button>
                    </div>
                </div>
                <div className="navbar__decorator"></div>
            </div>
        </>
    )
}
