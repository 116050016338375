import React from 'react'

export const Presentation = () => {
  return (
    <div className="presentation__main"
      style={{
        backgroundImage: "url('/assets/img/backgrounds/rsz_pexels-sharon-wahrmund-237779.jpg')"
      }}
    >
      <div className="presentation__content-container">
        <div className="presentation__content row d-flex">
          <div className="presentation__logo-container col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
            <img src="/assets/img/logos/rsz_11publicidad_md_logo.png"></img>
          </div>
          <div className="presentation__info-container col-lg-6 col-md-12">
            <div className="presentation__info-background d-flex justify-content-center align-items-center">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 648 590">
                <g>
                  <g>
                    <g>
                      <rect x="556" y="527" class="st0" width="16" height="16" />
                      <path class="st0" d="M460,127V63H332V47h-16v16H188v172.9l-48-27.7V207h-2l-1.7-1l-0.6,1H76v112c0,17.6,14.4,32,32,32h80v32h70.2
				                l-28.8,144H76v16h240h16h208v-16H418.6l-28.8-144H460V255h64v-16h-13.8l14.5-57.9L495.1,144c30,5.3,52.9,31.5,52.9,63h16
				                c0-26.2-12.7-49.4-32.2-64H572v-16H460z M124,319c0,8.8-7.2,16-16,16s-16-7.2-16-16v-96h32V319z M183.1,257.2l16,27.7l-51.7,29.9
				                l32-55.4L183.1,257.2z M140,231.6l2.2-3.7l27.7,16L140,295.6V231.6z M144.3,335l55.4-32h4.3v32H144.3z M316,527h-70.2l28.8-144
				                H316V527z M402.2,527H332V383h41.4L402.2,527z M443.3,181.1l14.5,57.9H444v8v8v112H204v-16h16v-59.7l1-0.6l-1-1.7v-2h-1.2
				                L204,261.3V79h240v48h-48v16h40.2c-19.5,14.6-32.2,37.8-32.2,64h16c0-31.5,22.9-57.7,52.9-63L443.3,181.1z M507.3,184.9
				                L493.8,239h-19.5l-13.5-54.1l15.3-19.1V199h16v-33.2L507.3,184.9z"/>
                      <rect x="236" y="111" class="st0" width="16" height="16" />
                      <rect x="396" y="319" class="st0" width="16" height="16" />
                      <path class="st0" d="M396,223c0-39.7-32.3-72-72-72c-12.9,0-25.1,3.5-35.6,9.5l-22-30.2l-12.9,9.4l22,30.2
				                C261.1,183.1,252,202,252,223c0,39.7,32.3,72,72,72c12.9,0,25.1-3.5,35.6-9.5l22,30.2l12.9-9.4l-22-30.2
				                C386.9,262.9,396,244,396,223z M324,279c-30.9,0-56-25.1-56-56c0-15.7,6.5-29.9,17-40.1l65.1,89.6C342.3,276.6,333.4,279,324,279
				                z M297.9,173.5c7.8-4.1,16.7-6.5,26.1-6.5c30.9,0,56,25.1,56,56c0,15.7-6.5,29.9-17,40.1L297.9,173.5z"/>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div className="presentation__info-wrapper">
              <h1 className="base__fsc-10 base__fs --color-white --raleway --w-bold">MD Publicidad Marketing & Diseño</h1>
              <h2 className="base__fs --color-white --poppins --space-y-m --w-bold">Somos una Empresa creativa con más de 10 años en donde: imaginamos, 
diseñamos y producimos Stands, Eventos y Espacios Comerciales creando 
experiencias de exhibición innovadoras</h2>
              <div className="presentation__info-imgs row">
                <div className="col-md-4 col-6">
                  <img src='/assets/img/illustration/presentation/pexels-toa-heftiba-şinca-1194420.jpg' />
                </div>
                <div className="col-md-4 col-6">
                  <img src='/assets/img/illustration/presentation/pexels-pixabay-276267.jpg' />
                </div>
                <div className="col-md-4 col-6">
                  <img src='/assets/img/illustration/presentation/pexels-pixabay-271667.jpg' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

{/* <div className="presentation__animation-container" >
              <ul className="presentation__animation-items">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div > */}
