import React, { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { useNavbarScroll } from '../../hooks/useNavbarScroll'
import { useModalComponent } from '../../hooks/useModalComponent'

import { Presentation } from '../presentation/Presentation'
import { EventsSection } from '../eventsSection/EventsSection'
import { StandsSection } from '../standsSection/StandsSection'
import { ValuesSection } from '../valuesSection/ValuesSection'
import { ContactSection } from '../contactSection/ContactSection'
import { ClientsSection } from '../clientsSection/ClientsSection'

import "swiper/css";
import "swiper/css/pagination";

export const IndexScreen = () => {

    const location = useLocation()

    const { containerRef } = useNavbarScroll({ locationState: location.state })

    const {ReactModal, setModalComponent, setModalIsOpen, handleModalClose } = useModalComponent({ initialStyle: { maxHeight: '80vh', maxWidth: '100vw' } });

    return (
        <>
            <Helmet>
                <title>Publicidad md - Inicio</title>
                {/* <meta name="description" content='Somos una empresa de vanguardia con mucha tradición, dedicados a la comercialización de cárnicos nacionales e importados, con vocación de servicio y la visión satisfacción total a los hogares y negocios mexicanos con excelentes productos y precios con el fin de que pienses. «Super Brangus es la Carnicería Cerca de Mi»'/>
                <meta name="keywords" content="super brangus, súper brangus, carniceria, comida, insumos, lacteos, pollo, pescado, cerdo, res"/> */}
            </Helmet>
            <ReactModal />
            <div className="base__sections-container" ref={containerRef}>
                <section className="sec-presentation" id="sec-presentation">
                    <Presentation />
                </section>
                <section className="sec-events" id="sec-events">
                    <EventsSection 
                        setModalIsOpen={setModalIsOpen} 
                        setModalComponent={setModalComponent}
                        handleModalClose={handleModalClose}
                    />
                </section>
                <div className="indexScreen__sections-container">
                    <section className="sec-stands" id="sec-stands">
                        <StandsSection 
                            setModalIsOpen={setModalIsOpen} 
                            setModalComponent={setModalComponent}
                            handleModalClose={handleModalClose}
                        />
                    </section>
                    <section className="sec-values" id="sec-values">
                        <ValuesSection />
                    </section>
                </div>
                <section className="sec-clients" id="sec-clients">
                    <ClientsSection />
                </section>
                <section className="sec-contact" id="sec-contact">
                    <ContactSection />
                </section>
            </div>
        </>
    )
}
