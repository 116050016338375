import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Parallax } from 'react-scroll-parallax'

import { useCustomParallax } from '../../hooks/useCustomParallax'

import { simpleFadeUp, simpleFadeIn } from '../../style/motion/variants'

import { MediaViewModalComponent } from '../ui/modals/MediaViewModalComponent'
import { Slider_1 } from '../ui/sliders/Slider_1'

import _settings from '../../style/config/_settings.scss'

export const StandsSection = ({ setModalIsOpen, setModalComponent, handleModalClose }) => {

    const viewportOptions = { once: true, margin: "0px 0px  -300px 0px" }

    const { isAnimationOn } = useCustomParallax()

    const [imgs, setImgs] = useState([
        "/assets/img/illustration/stands/innovation/innovation_1.jpg",
        "/assets/img/illustration/stands/innovation/innovation_2.jpg",
        "/assets/img/illustration/stands/innovation/innovation_3.jpg",
        "/assets/img/illustration/stands/innovation/innovation_4.jpg",
        "/assets/img/illustration/stands/dentsply/dentsply_1.jpg",
        "/assets/img/illustration/stands/dentsply/dentsply_2.jpg",
        "/assets/img/illustration/stands/dentsply/dentsply_3.jpg",
        "/assets/img/illustration/stands/ilsa/ilsa_1.jpg",
        "/assets/img/illustration/stands/ilsa/ilsa_2.jpg",
        "/assets/img/illustration/stands/ilsa/ilsa_3.jpg",
        "/assets/img/illustration/stands/ilsa/ilsa_4.jpg",
        "/assets/img/illustration/stands/ilsa/ilsa_5.jpg",
        "/assets/img/illustration/stands/ilsa/ilsa_6.jpg",
        "/assets/img/illustration/stands/ilsa/ilsa_7.jpg",
        "/assets/img/illustration/stands/kezelmedica/kezel_1.jpg",
        "/assets/img/illustration/stands/kezelmedica/kezel_2.jpg",
        "/assets/img/illustration/stands/kezelmedica/kezel_3.jpg",
        "/assets/img/illustration/stands/kezelmedica/kezel_4.jpg",
        
    ])

    const handleSlideClick = ({ target }) => {
        setModalIsOpen(true)
        setModalComponent({
            component: MediaViewModalComponent,
            props: { imgSrc: target.src, handleModalClose }
        })
    }

    return (
        <div className="standsSection__main">
            <div className="standsSection__container">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 standsSection__sec-1-img-container">
                            <Parallax speed={7} disabled={isAnimationOn}>
                                <motion.h1 className="base__fsc-11 base__fs --w-bold --color-tertiary"
                                    variants={simpleFadeUp(0)}
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={viewportOptions}
                                >Diseño y producción de stands</motion.h1>
                            </Parallax>
                            {/* <div className="standsSection__sec-1-img-background"></div> */}
                            <div className="standsSection__sec-1-img-wrapper">
                                <Parallax speed={-5} disabled={isAnimationOn}>
                                    <img src="/assets/img/illustration/stands/coacervate_1.png" />
                                </Parallax>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 standsSection__sec-1-info-container">
                            <div className="standsSection__sec-1-img-spacer"></div>
                            <Parallax speed={7} disabled={isAnimationOn}>
                                <motion.h2 className="base__fs --w-bold --color-secondary"
                                    variants={simpleFadeUp(0)}
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={viewportOptions}
                                >Es tiempo de darle presencia a tu marca</motion.h2>
                                <motion.div
                                    variants={simpleFadeIn(0)}
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={viewportOptions}
                                >
                                    <img src="/assets/img/illustration/stands/innovation_1.jpg" />
                                    <p className="base__fsc-2 base__fs --w-bold --color-dark-blue --raleway --space-y-m">El stand representa la identidad corporativa de una empresa y constituye el principal factor de atracción de visitantes para exponer sus productos y servicios, por lo que en Publicidad MD nos encargamos de diseñar stands atractivos y de gran impacto visual para proyectar la imagen que necesita tu empresa.</p>
                                </motion.div>
                            </Parallax>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center standsSection__slider-container">
                    <div className="standsSection__slider-wrapper">
                        <Slider_1 imgs={imgs} onClickCb={handleSlideClick} fill={_settings.themeSecondaryOrange} />
                    </div>
                </div>
                <div className="container">
                    <motion.div className="row"
                        variants={simpleFadeUp(0)}
                        initial="hidden"
                        whileInView="visible"
                        viewport={viewportOptions}
                    >
                        <div className="col-md-4 standsSection__sec-2-title-img-container p-0">
                            <img src="/assets/img/illustration/stands/stands-illustration-1.png" />
                        </div>
                        <div className="col-md-8 standsSection__sec-2-title-container d-flex p-0">
                            <div className="standsSection__sec-2-title-separator"></div>
                            <h1 className="base__fsc-11 base__fs --w-bold --color-tertiary">Somos tu mejor elección</h1>
                        </div>
                    </motion.div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="standsSection__sec-2-decorator-container">
                            <motion.div className="col-md-4 standsSection__sec-2-decorator-wrapper p-0"
                                variants={simpleFadeIn(0)}
                                initial="hidden"
                                whileInView="visible"
                                viewport={viewportOptions}
                            >
                                <Parallax translateY={[0, 20]}>
                                    <div className="cover"></div>
                                    <img src="assets/img/illustration/stands/decorator_1.png" />
                                </Parallax>
                            </motion.div

                            >
                            <div className="row standsSection__info-container">
                                <div className="col-md-7">
                                    <Parallax translateY={[0, 20]} disabled={isAnimationOn}>
                                        <h3 className="standsSection__info-title ps-3 base__fsc-7 base__fs --poppins --color-secondary --w-bold">¡Creatividad!</h3>
                                        <p className="base__fs --w-bold --raleway --color-dark-blue --space-y-m">Contamos con un equipo de expertos creativos que están a su disposición para asesorarle y apoyarle a la hora de construir cualquier proyecto. diseño stands para ferias</p>
                                    </Parallax>
                                </div>
                                <div className="col-md-5 standsSection__info-img-container">
                                    <Parallax translateY={[-10, -50]} disabled={isAnimationOn}>
                                        <motion.img src="/assets/img/illustration/stands/DSC05999.jpg"
                                            variants={simpleFadeIn(0)}
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={viewportOptions}
                                        />
                                    </Parallax>
                                </div>
                                <div className="col-md-7">
                                    <Parallax translateY={[0, 20]} disabled={isAnimationOn}>
                                        <h3 className="standsSection__info-title base__fsc-7 base__fs --poppins --color-secondary --w-bold ps-4">¡BTL exclusivo para su marca!</h3>
                                        <p className="base__fs --w-bold --raleway --color-dark-blue --space-y-m ps-4">Nuestro BTL está al alcance de numerosas empresas que no pueden hacer frente a grandes inversiones publicitarias. Únicamente necesitan una amplia dosis de ingenio.
                                            A mayor impacto, mejores resultados.</p>
                                    </Parallax>
                                </div>
                                <div className="col-md-5 standsSection__info-img-container mt-3">
                                    <Parallax translateY={[-10, -50]} disabled={isAnimationOn}>
                                        <motion.img src="/assets/img/illustration/stands/DJI_0102.jpg"
                                            variants={simpleFadeIn(0)}
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={viewportOptions}
                                        />
                                    </Parallax>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
