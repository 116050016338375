import React, { useState} from 'react';
import { Parallax } from 'react-scroll-parallax';
import { motion } from "framer-motion";

import { simpleFadeRight, simpleFadeUp, simpleFadeIn } from '../../style/motion/variants';
import { useCustomParallax } from '../../hooks/useCustomParallax';

import { MediaViewModalComponent } from '../ui/modals/MediaViewModalComponent';
import { Slider_1 } from '../ui/sliders/Slider_1';

import _settings from '../../style/config/_settings.scss';

export const EventsSection = ({ setModalIsOpen, setModalComponent, handleModalClose }) => {

  const [ imgs, setImgs ] = useState([
    "/assets/img/illustration/eventos/04BC0AA6-E70D-42D7-A6AF-FF2A7C3D0397.JPG",
    "/assets/img/illustration/eventos/5C1217C7-5BFB-4FD2-8400-9405CF72184D.JPG",
    "/assets/img/illustration/eventos/F935DA51-B287-436B-AB9D-04EC5D326F48.JPG",
    "/assets/img/illustration/eventos/IMG_0766.JPG",
    "/assets/img/illustration/eventos/IMG_0782.JPG",
    "/assets/img/illustration/eventos/IMG_6472.JPG"
  ]);

  const { isAnimationOn } = useCustomParallax(); 

  const viewportOptions = { once: true, margin: "0px 0px  -300px 0px" };

  const handleSlideClick = ({target}) => {
    setModalIsOpen(true)
    setModalComponent({
      component: MediaViewModalComponent,
      props: { imgSrc: target.src, handleModalClose }
    })
  }

  return (
    <div className="eventsSection__main base__background --fixed"
      style={{
        backgroundImage: "url(/assets/img/backgrounds/DJI_0093.jpg)"
      }}
    >
      <div className="base__background-cover --color-primary"></div>
      <div className="eventsSection__container container">

        <div className="row eventsSection__welcome-row">
          <motion.h1 className="base__fs --w-bold --poppins --color-white base__fsc-hero"
            variants={simpleFadeRight(0)}
            initial="hidden"
            whileInView="visible"
            viewport={viewportOptions}
          >Bienvenido a Publicidad MD Stands y Eventos México</motion.h1>
          <div className="col-md-6">
            <motion.p className="base__fs --raleway --w-bold --color-white --space-y-l base__fsc-6 mt-3"
              variants={simpleFadeIn(0)}
              initial="hidden"
              whileInView="visible"
              viewport={viewportOptions}
            >Somos un grupo de creativos encargados de fabricar y diseñar stands para su marca, nos encargamos de contextualizar su producto o servicio en ideas, para luego dejarlas plasmadas en su mejor exposición. </motion.p>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <motion.img src='/assets/img/decorators/team-success-svgrepo-com.png'
              variants={simpleFadeIn(0.5)}
              initial="hidden"
              whileInView="visible"
              viewport={viewportOptions}
            />
          </div>
        </div>

        <div className="row" style={{ position: "relative" }}>
          <Parallax disabled={ isAnimationOn } translateY={[-30, 50]}>
            <motion.h1
              className="base__fs --w-bold --poppins --color-white base__fsc-hero-2"
              variants={simpleFadeRight(0.5)}
              initial="hidden"
              whileInView="visible"
              viewport={viewportOptions}
            >Eventos y Convenciones</motion.h1>
          </Parallax>
          <div className="col-md-6">
            <Parallax disabled={ isAnimationOn } translateY={[-10, 50]}>
              <motion.p
                className="base__fs --raleway --w-bold --color-white --space-y-l base__fsc-5"
                variants={simpleFadeRight(0.7)}
                initial="hidden"
                whileInView="visible"
                viewport={viewportOptions}
              >
                Decoramos y ambientamos tu evento, haciendo que tu experiencia sea especial, emocionante e inolvidable.
                <br />
                <br />
                Cubrimos eventos, convenciones, workshops, lanzamientos de marca, activaciones, ferias, simposios y congresos.</motion.p>
            </Parallax>
          </div>
          <div className="col-md-6">
            <Parallax disabled={ isAnimationOn } translateY={[10, -10]}>
              <div className="eventsSection__polygon-1-wrapper">
                <motion.img
                  variants={simpleFadeIn(0)}
                  initial="hidden"
                  whileInView="visible"
                  viewport={viewportOptions}
                  src="/assets/img/illustration/vivanuncios1.png" />
              </div>
            </Parallax>
          </div>
        </div>
      </div>

      <div className="eventsSection__container">
        <motion.h1 className="base__fs --w-bold --poppins --color-white base__fsc-hero text-center"
          variants={simpleFadeIn(0)}
          initial="hidden"
          whileInView="visible"
          viewport={viewportOptions}
        >Algunas de Nuestras Convenciones</motion.h1>
        <div style={{ overflow: "hidden" }}>
          <Slider_1 imgs={imgs} onClickCb={handleSlideClick} fill={_settings.themeWhite} />
        </div>
      </div>
    </div>
  )
}
{/* <motion.div className="eventsSection__swiper-wrapper d-flex justify-content-center align-items-center"
              variants={simpleFadeUp(0.5)}
              initial="hidden"
              whileInView="visible"
              viewport={viewportOptions}
            ></motion.div> */}