import React from 'react'

export const MediaViewModalComponent = ({ imgSrc, handleModalClose }) => {
  return (
    <div className="mediaViewModal__main">
        <div className="mediaViewModal__controls">
          <button onClick={handleModalClose}>
          <i className="fa-solid fa-xmark base__fs --color-secondary"></i>
          </button>
        </div>
        <img src={imgSrc}/>
    </div>
  )
}
