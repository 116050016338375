import { AppRouter } from './routers/AppRouter';
import { ParallaxProvider } from 'react-scroll-parallax'

function App() {
  return (
    <ParallaxProvider>
      <AppRouter />
    </ParallaxProvider>
  );
}

export default App;
