const baseUrl = process.env.REACT_APP_API_URL;

export const fetchNoToken = async ( endpoint, data, method = "GET" ) => {

    const url = `${ baseUrl }/${ endpoint }`;

    if( method === "GET" ) {
        return fetch( url )
    }

    return fetch( url, {
        method: method,
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify( data )
    } );


}

export const fetchToken = ( endpoint, data, method = "GET" ) => {

    const url = `${ baseUrl }/${ endpoint }`;

    const token = localStorage.getItem('token') || '';

    if( method === "GET" ) {
        return fetch( url, {
            method,
            headers: {
                'x-token': token
            }
        } );
    }

    return fetch( url, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'x-token': token
        },
        body: JSON.stringify( data )
    } )
}