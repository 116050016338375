import React, { useState, useRef, useEffect } from 'react'

export const useNavbarScroll = ({ locationState }) => {

    const containerRef = useRef(null)

    const [ section, setSection ] = useState(null)
    const targetSection = useRef(null);

    useEffect(() => {

        if (locationState) {
            setSection(locationState.split("&")[0]);
        }

        if(section && containerRef.current) {

            targetSection.current = containerRef.current.querySelector(`#${section}`);

            console.log("section:", targetSection);

            (targetSection.current) && targetSection.current.scrollIntoView();
        }
    }, [locationState, section])

    return { containerRef }
}
