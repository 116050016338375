import React, { useState } from 'react'
import Modal from 'react-modal'

Modal.setAppElement('#root')

export const useModalComponent = ({ initialStyle }) => {

    const [ modalIsOpen, setModalIsOpen ] = useState(false)
    const [ modalStyle, setModalStyle ] = useState(initialStyle)
    const [ modalComponent, setModalComponent ] = useState({
        component: () => {}, 
        props: {}
    })

    const Component = modalComponent.component;

    const handleModalClose = () => {
        setModalIsOpen(false)
        setModalComponent({
            component: () => {},
            props: {}
        })
    }

    const ReactModal = () => (
        <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={handleModalClose}
                style={modalStyle}
                contentLabel=""
                className="reactModal__modal"
                overlayClassName="reactModal__modal-overlay"
                CloseTimeoutMS={200}
            >
                <Component { ...modalComponent.props } />
            </Modal>
        </>
    )

    return { ReactModal, setModalIsOpen, setModalComponent, setModalStyle, handleModalClose};
}
