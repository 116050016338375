import React, { useState, useEffect } from 'react'

export const ValuesSection = () => {

    const [activeText, setActiveText] = useState("Desde 2010, estamos dedicados en exclusiva al diseño y montaje de stands de feria. Son más de 10 años de experiencia en el mundo de los eventos feriales; en los cuales hemos sabido representar a un gran número de empresas nacionales e internacionales. Somos expertos en identificar tanto su imagen corporativa como el producto a exponer; y reflejarlos en un stand diseñado exclusivamente para usted. Somos partícipes de impulsar la atención personalizada a nuestros clientes para garantizar el éxito de nuestros servicios. Mano a mano, ultimamos los elementos del stand para posteriormente encargarnos de su construcción, su mantenimiento durante la feria, y posteriormente su desmontaje. Incluso si es necesario, almacenamos el stand para su reconstrucción en futuras ferias.");
    const [activeIndex, setActiveIndex] = useState("opt-2");
    
    useEffect(() => {

        if (activeIndex === "opt-1") {
            setActiveText("Somos una agencia creativa Mexicana dedicada al diseño, organización y producción de eventos, alquiler de mobiliario y diseño de escenarios y espacios corporativos, lanzamientos de marca y eventos BTL. Creamos eventos combinando la pasión, la experiencia y el talento creativo formando proyectos que cumplan al 100% con la expectativa final de nuestros clientes. Contamos con un grupo experto y capacitado para la creación y el desarrollo de proyectos efectivos, organizando todo el proceso de creación desde el primer contacto con el cliente: el diseño, planeación, producción, hasta la terminación efectiva del proyecto. Por eso nos apoyamos en recursos propios como mobiliario completo, Stands Modulares, Stands Diseñados, Carpas, así mismo un grupo de trabajo expertos en el área creativa, área productiva, bodegas, máquinas de impresión y branding, fabricación de backing, transporte y maquinaria de producción.");
        }

        if (activeIndex === "opt-2") {
            setActiveText("Desde 2010, estamos dedicados en exclusiva al diseño y montaje de stands de feria. Son más de 10 años de experiencia en el mundo de los eventos feriales; en los cuales hemos sabido representar a un gran número de empresas nacionales e internacionales. Somos expertos en identificar tanto su imagen corporativa como el producto a exponer; y reflejarlos en un stand diseñado exclusivamente para usted. Somos partícipes de impulsar la atención personalizada a nuestros clientes para garantizar el éxito de nuestros servicios. Mano a mano, ultimamos los elementos del stand para posteriormente encargarnos de su construcción, su mantenimiento durante la feria, y posteriormente su desmontaje. Incluso si es necesario, almacenamos el stand para su reconstrucción en futuras ferias.");
        }

        if (activeIndex === "opt-3") {
            setActiveText("Nuestra visión es clara: ser la empresa líder en la elaboración de stands para ferias. Consolidarnos a nivel nacional, ofreciendo nuestros servicios en todas las ciudades principales de México.");
        }
    }, [activeIndex])

    return (
        <div className="valuesSection__main">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 valuesSection__img-container">
                        <img src="/assets/img/illustration/valores/rsz_pexels-anna-nekrashevich-6801647.jpg" />
                    </div>
                    <div className="col-lg-6 col-md-12 valuesSection__info-container">
                        <h1 className="base__fsc-hero base__fs --poppins --w-bold --color-tertiary">Nuestra Proyección</h1>
                        <h2 className="base__fsc-9 base__fs --poppins --w-bold --color-secondary">¡Somos un equipo 100% creativo!</h2>
                        <p className="base__fsc-2 base__fs --raleway --color-dark-blue --space-y-m">Somos una empresa mexicana e innovadora con una trayectoria de 10 años, dedicados a transformar la imaginación de nuestros clientes en realidad.
                            <br /><br />
                            Ofreciendo la mejor calidad en nuestro trabajo creamos y diseñamos con gran originalidad visual.</p>
                    </div>
                    <div className="valuesSection__values">
                        <div className="row">
                            <div className="col-lg-2 col-md-12 valuesSection__values-menu-container">
                                <div className="valuesSection__menu-wrapper">
                                    <p className={`${(activeIndex==="opt-1") && "active"}`} onClick={() => setActiveIndex("opt-1")}>Valores</p>
                                    <p className={`${(activeIndex==="opt-2") && "active"}`} onClick={() => setActiveIndex("opt-2")}>Misión</p>
                                    <p className={`${(activeIndex==="opt-3") && "active"}`} onClick={() => setActiveIndex("opt-3")}>Visión</p>
                                </div>
                            </div>
                            <div className="col-lg-10 col-md-12 valuesSection__values-info">
                                <p className="base__fs --raleway --color-dark-blue">{activeText}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
