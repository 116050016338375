export const simpleFadeRight = (delay) => (
    {
        hidden: { opacity: 0, x:"-50%", transition: { delay } },
        visible: { opacity: 1, x:0, transition: { delay } }
    }
);

export const simpleFadeIn = (delay) => (
    {
        hidden: { opacity: 0, transition: { delay } },
        visible: { opacity: 1, transition: { delay } }
    }
)

export const simpleFadeLeft = (delay) => (
    {
        hidden: { opacity: 0, x:"50%", transition: { delay } },
        visible: { opacity: 1, x:0, transition: { delay } }
    }
);

export const simpleFadeUp = (delay) => (
    {
        hidden: { opacity: 0, y:"50%", transition: { delay } },
        visible: { opacity: 1, y:0, transition: { delay } }
    }
);

export const infiniteScroll = {   
        initial: {
            x: "0%",
            y: "0"
        },
        animate: { 
            x: ["0%", "-50%", "0%"],
            y: ["0%", "10%", "0%"]
        }
}