import React from 'react'

export const ChatButton = () => {
    return (
        <button className="chatButton__wrapper">
            <a href='https://api.whatsapp.com/send?phone=525567025558&text=Chat Web MD Publicidad: Hola buen día'>
                <i class="fa-solid fa-comment-dots"></i>
            </a>
        </button>
    )
}
