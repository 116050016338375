import React from 'react'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { Navbar } from '../components/ui/nav/Navbar';

import { IndexScreen } from '../components/screens/IndexScreen';
import { ChatButton } from '../components/ui/buttons/ChatButton';

export const AppRouter = () => {
    return (
        <HashRouter>
            <Navbar />
            <ToastContainer
                position='bottom-center'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            >
                { }
            </ToastContainer>
            <ChatButton />
            <Routes>
                <Route path="/index" element={<IndexScreen />} />
                <Route path="*" element={<Navigate replace to="/index" />} />
            </Routes>
        </HashRouter>
    )
}
