import React, { useEffect, useState } from 'react'

export const useCustomParallax = () => {

    const [ isAnimationOn, setIsAnimationOn ] = useState(false);

    useEffect(() => {

        checkWindowWidth(window.innerWidth)

        window.addEventListener('resize', checkWindowWidth)

        return () => {
            window.removeEventListener('resize', checkWindowWidth)
        }

    }, [])

    const checkWindowWidth = () => {
        console.log("Checking width")
        if(window.innerWidth >= 992) {
            setIsAnimationOn(false)
        }

        if(window.innerWidth < 992) {
            setIsAnimationOn(true)
        }
    }

    return { isAnimationOn }
}
